import React from 'react';

import location from '../../assets/images/icons/location.svg'
import phone from '../../assets/images/icons/phone.svg'
import email from '../../assets/images/icons/email.svg'

function ContactUs({ className }) {
  return (
    <div className={`${className} contact-us-form`} >
      <h3 className="mb-32">Contact</h3>
      <ul className="ml-0 mt-4">
        <li>
          <a className="" href="mailto:support@lawadvisor.com">
            <img src={email} /><h6 className="pl-2 d-inline text-muted">support@lawadvisor.com</h6>
          </a>
        </li>
        { false &&
        <li>
          <a className="" href="tel:091234567890">
            <img src={phone} /><h6 className="pl-2 d-inline text-muted">091234567890</h6>
          </a>
        </li> }
        <li>
          <img src={location} /><h6 className="pl-2 d-inline text-muted">London</h6>
        </li>
      </ul>
    </ div>
  )
};

export default ContactUs;
