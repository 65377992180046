import { Link } from 'gatsby';
import React, { useState } from 'react';
import { CountryDropdown } from 'react-country-region-selector';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { submitForm } from '../../utils/api';
import isValidEmail from '../../utils/email';
import Alert from '../common/Alert';

function SubmitApplication(props) {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const [submitted, setSubmitted] = useState(null);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [title, setTitle] = useState('');
  const [country, setCountry] = useState('');

  const submitApplicationAction = async (e) => {
    e.preventDefault();
    const params = {
      email: email,
      full_name: name,
      jobtitle: title,
      country: country,
      partnership_type: 'Work With Us',
    };

    if (isValidEmail(email)) {
      const token = await executeRecaptcha('careersApplication')

      submitForm({ ...params, token })
        .then((res) => {
          if (res.ok) {
            setSubmitted(true);
          } else {
            setSubmitted(false);
          }
        })
        .catch(() => setSubmitted(false));
    } else {
      setSubmitted(false);
    }
  };

  return (
    <div className="mb-5 px-3">
      {!submitted && (
        <div>
          <h2>
            Hello. <br />
            We’ve been expecting you!
          </h2>
          <h4 className="text-muted mb-32">
            Let's discuss how we can build your tech-enabled practice together
          </h4>
          <div className="col-xl-9 col-md-9 col-sm-12 pr-0 pl-0">
            <form
              className="contact-us-form"
              onSubmit={submitApplicationAction}
            >
              <div className="form-group mb-24">
                <label className="mb-8" htmlFor="exampleInputEmail1">
                  Full name
                </label>
                <input
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                  type="text"
                  className="form-control text-secondary p-16"
                  placeholder="What’s your full name?"
                />
              </div>
              <div className="form-group mb-24">
                <label className="mb-8" htmlFor="exampleInputEmail1">
                  Email
                </label>
                <input
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  type="email"
                  className="form-control text-secondary p-16"
                  placeholder="What’s your work email?"
                />
              </div>
              <div className="form-group mb-24">
                <label className="mb-8" htmlFor="exampleInputEmail1">
                  Job Title
                </label>
                <input
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  type="text"
                  className="form-control text-secondary p-16"
                  placeholder="And your role title?"
                />
              </div>
              <div className="form-group">
                <label className="mb-8" htmlFor="exampleInputEmail1">
                  Country
                </label>
                <CountryDropdown
                  classes="select form-control text-secondary"
                  onChange={(country) => {
                    setCountry(country);
                  }}
                  value={country}
                  priorityOptions={['CA', 'US', 'GB', 'SG', 'AU']}
                />
              </div>
              <div className="grecaptcha-attribution mb-24">
                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
              </div>
              <Alert notification={submitted} />
              <button className="btn btn-primary float-right get-in-touch p-16">
                Get in Touch
              </button>
            </form>
          </div>
        </div>
      )}
      {submitted && (
        <div>
          <h1>
            Awesome. We're looking forward to creating the future of law with
            you!
            <br />
            We'll be in touch.
          </h1>
          <a href="/" className="btn btn-sm btn-outline-primary mt-3 mb-5">
            Return to homepage
          </a>
        </div>
      )}
    </div>
  );
}

export default SubmitApplication;
