import React from 'react';
import { Helmet } from 'react-helmet';
import ContactUs from '../components/work-with-us/ContactUs';
import SocialMedia from '../components/work-with-us/SocialMedia';
import SubmitApplication from '../components/work-with-us/SubmitApplication';

function JobPost() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://fonts.googleapis.com/icon?family=Material+Icons" />
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
        <link rel="stylesheet" type="text/css" charset="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
        <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />
        <title>LawAdvisor Ventures</title>
        <meta name="title" content="LawAdvisor Ventures" />
        <meta name="description" content="LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: that everybody should have access to justice." />
        <meta name="image" content="/metaimages/meta-image-la-ventures.png" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lawadvisor.ventures/work-with-us" />
        <meta property="og:title" content="LawAdvisor Ventures" />
        <meta property="og:description" content="LawAdvisor was founded in 2015 in Melbourne, Australia with a fearless and ambitious belief: that everybody should have access to justice." />
        <meta property="og:image" content="https://lawadvisor.ventures/metaimages/meta-image-la-ventures.png" />
      </Helmet>
      <div className="row fit-width px-0 mt-60">
        <div className="col-xl-9 col-md-9 col-sm-12 pl-0">
          <SubmitApplication />
        </div>
        <div className="col-xl-3 col-md-3 d-none d-sm-block pl-0 pr-0">
          <div className="card contact-us">
            <div className="card-body">
              <ContactUs />
              {false && <SocialMedia className="mt-5" />}
            </div>
          </div>
        </div>
        <div className="col-sm-12 d-sm-none contact-us mt-5 pl-0 pr-0" style={{ margingLeft: '-15px', margingRight: '-15px' }}>
          <div className="card contact-us">
            <div className="card-body">
              <ContactUs />
              {false && <SocialMedia className="mt-5" />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default JobPost;
