import React from 'react';


function SocialMedia({ className }) {
  return (
    <div>
      <div className={`${className || ''}`}>
        <h3 className="">Social Media</h3>
        <ul className="ml-0 mt-4">
          <li>
            <a target="_blank" href="https://www.linkedin.com/company/lawadvisor"><h6 className="d-inline text-muted">LinkedIn</h6></a>
          </li>
          <li>
            <a target="_blank" href="https://www.facebook.com/LawAdvisorAustralia"><h6 className="d-inline text-muted">Facebook</h6></a>
          </li>
          <li>
            <a target="_blank" href="https://medium.com/@LawAdvisor"><h6 className="d-inline text-muted">Medium</h6></a>
          </li>
          <li>
            <a target="_blank" href="https://twitter.com/lawadvisoraus"><h6 className="d-inline text-muted">Twitter</h6></a>
          </li>
        </ul>
      </div>
    </div>
  )
};

export default SocialMedia;