import React from 'react';

function Alert({ notification }) {
  return (
    <div
      className={`form-response ${notification != null ? 'd-block' : 'd-none'}`}
      style={{
        display: notification == null ? 'none' : 'flex', margin: '0 auto', fontSize: '14px',
      }}
    >
      {notification && (<i className="fa fa-check text-success" alt="check icon" />)}
      <div className={`message ${notification === true ? '-success text-success' : 'text-danger'}`}>
        {notification
          ? 'Please check your email for further details.'
          : 'Something went wrong. Please try again.'}
      </div>
    </div>
  );
}

export default Alert;